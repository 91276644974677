export enum PASSWORD_STRENGTH {
  ONE_UPPER_CASE = 'One Upper Case Alphabet',
  ONE_LOWER_CASE = 'One Lower Case Alphabet',
  ONE_NUMBER = 'One Number',
  ONE_SPECIAL_CHARACTER = 'One Special Character ( . , @ # $ % )',
  MINIMUM_EIGHT = 'Minimum 8 Characters',
}

export const FOOTER_TEXT = {
  COPYRIGHT: `© 2013 - ${new Date().getFullYear()}`,
  COMPANY_NAME: 'YOUGotaGift.com Ltd.',
  PRIVACY_POLICY: 'https://yougotagift.com/shop/en-ae/privacy-policy/',
  TERMS_USE: 'https://yougotagift.com/shop/en-ae/terms-of-use/',
  POWERED_BY: 'Powered by',
  LOGO_SRC: '/assets/ygag-logo.png',
};

export enum FILTER_TYPES {
  TOP_UP = 'top-up',
  DISCOUNT = 'discount',
  BUY_GET = 'buy-and-get',
  PROMO_CODE = 'promo-code',
}

export enum FILTER_BY_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  // EXPIRED = 'expired',
}

export enum SORT_OPTIONS {
  A_Z = 'aZ',
  Z_A = 'zA',
  OLDEST = 'oldest',
  NEWEST = 'newest',
}

export enum INPUT_TYPES {
  EMAIL = 'email',
  PASSWORD = 'password',
  VERIFICATION_CODE = 'verification_code',
}

export enum SNACK_BAR_TYPES {
  VERIFICATION_CODE_SEND = 'verificationCodeSent',
  REACED_THE_LIMIT = 'reachedTheLimit',
  PASSWORD_RESET_SUCCESS = 'passwordResetSuccess',
}

export enum PAGE_TYPES {
  HOME = 'home',
  CREATE_NEW_OFFER = 'create-new-offer',
  REPORTS = 'reports',
}

export enum OFFER_TYPES {
  TOP_UP = 'top-up',
  DISCOUNT = 'discount',
  BUY_AND_GET = 'buy-and-get',
}

export enum OTP {
  OTP_LENGTH = 8,
}

export enum STATUS_CODES {
  OK = 200,
  BAD_REQUEST = 400,
  SESSION_EXPIRED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  NO_NETWORK = 503,
}

export const CHARACTER_LIMITS: Record<string, number> = {
  OFFER_DESCRIPTION_AR: 5000,
  OFFER_DESCRIPTION_EN: 2000,
  OFFER_TERMS_AR: 5000,
  OFFER_TERMS_EN: 2000,
  OFFER_LABEL_EN: 50,
  OFFER_LABEL_AR: 100,
};
